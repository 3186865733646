export default {
  handler_abbr: { defaultValue: '' },
  name: { defaultValue: '' },
  address: { defaultValue: '' },
  city: { defaultValue: '' },
  state: { defaultValue: '' },
  country: { defaultValue: '' },
  zip: { defaultValue: '' },
  phone: { defaultValue: '' },
  pro_handler: { defaultValue: false },
  amateur_handler: { defaultValue: false },
  is_active: { defaultValue: true }
}
